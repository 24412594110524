import { PermissionEnum } from '@sr-sdks/api-sdk-axios';
import React, { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { ManageWorkspacePermissions } from '../types/workspaceTypes';
import { ProtectLazyLoadRoute } from './LazyLoadElement';

export const WorkspaceRoutes: RouteObject[] = [
  {
    element: ProtectLazyLoadRoute(
      lazy(() => import('../layout/WorkspaceLayout')),
    ),
    loader: () => {
      return {
        // WorkspaceLayout expects loaderData,
        permissionRequired: [PermissionEnum.ViewWorkspace],
      };
    },
    children: [
      {
        index: true,
        element: <Navigate replace to="titles" />,
      },
      {
        path: 'titles',
        loader: () => ({
          permissionRequired: [PermissionEnum.ViewTitles],
          title: 'Titles',
        }),
        children: [
          {
            index: true,
            element: ProtectLazyLoadRoute(
              lazy(() => import('../pages/Workspace/Titles')),
            ),
          },
          {
            path: 'summary/:titleId',
            element: ProtectLazyLoadRoute(
              lazy(() => import('../pages/Workspace/Title/TitleSummary')),
            ),
            loader: () => ({
              permissionRequired: [PermissionEnum.ViewTitles],
              title: 'Title Summary',
            }),
            children: [
              {
                index: true,
                element: <Navigate replace to="payments" />,
              },
              {
                path: 'payments',
                element: ProtectLazyLoadRoute(
                  lazy(() => import('../pages/Workspace/Title/Payments')),
                ),
                loader: () => ({
                  permissionRequired: [PermissionEnum.ViewCascades],
                  title: 'Title Summary',
                }),
              },
              {
                path: 'activity-statements',
                element: ProtectLazyLoadRoute(
                  lazy(
                    () => import('../pages/Workspace/Title/ActivityStatement'),
                  ),
                ),
                loader: () => ({
                  permissionRequired: [PermissionEnum.ViewCascades],
                  title: 'Title Summary',
                }),
              },
              {
                path: 'marketing-agreements',
                element: ProtectLazyLoadRoute(
                  lazy(
                    () =>
                      import('../pages/Workspace/Title/MarketingAgreements'),
                  ),
                ),
                loader: () => ({
                  permissionRequired: [PermissionEnum.ViewCascades],
                  title: 'Title Summary',
                }),
              },
              {
                path: 'sales-reports',
                element: ProtectLazyLoadRoute(
                  lazy(() => import('../pages/Workspace/Title/SalesReport')),
                ),
                loader: () => ({
                  permissionRequired: [PermissionEnum.ViewCascades],
                  title: 'Title Summary',
                }),
              },
            ],
          },
        ],
      },
      {
        path: 'payments',
        element: ProtectLazyLoadRoute(
          lazy(() => import('../pages/Workspace/Payments')),
        ),
        loader: () => ({
          permissionRequired: [PermissionEnum.ViewPaymentStatements],
          title: 'Payments',
        }),
      },
      {
        path: 'clients',
        element: ProtectLazyLoadRoute(
          lazy(() => import('../pages/Workspace/Clients')),
        ),
        loader: () => ({
          permissionRequired: [PermissionEnum.ViewClients],
          title: 'Clients',
        }),
      },
      {
        path: 'manage-workspace',
        element: ProtectLazyLoadRoute(
          lazy(() => import('../layout/ManageWorkspaceTabLayout')),
        ),
        loader: () => ({
          permissionRequired: ManageWorkspacePermissions,
          title: 'Settings',
        }),
        children: [
          {
            index: true,
            element: <Navigate replace to="details" />,
          },
          {
            path: 'details',
            element: ProtectLazyLoadRoute(
              lazy(() => import('../pages/Workspace/ManageWorkspace/Details')),
            ),
            loader: () => ({
              permissionRequired: [PermissionEnum.EditWorkspaceDetails],
            }),
          },
          {
            path: 'bank-account',
            element: ProtectLazyLoadRoute(
              lazy(
                () =>
                  import('../pages/Workspace/ManageWorkspace/BankAccountV2'),
              ),
            ),
            loader: () => ({
              permissionRequired: [PermissionEnum.EditBankDetails],
            }),
          },
          {
            path: 'teammates',
            element: ProtectLazyLoadRoute(
              lazy(
                () => import('../pages/Workspace/ManageWorkspace/Teammates'),
              ),
            ),
            loader: () => ({
              permissionRequired: [PermissionEnum.EditTeammates],
            }),
          },
        ],
      },
    ],
  },
];
